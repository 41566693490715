const modalData: { [key: string]: any } = {};

export const getModalData = (id: string | number) => modalData[id];

export const setModalData = (id: string | number, data: any) => {
  if (!modalData[id]) {
    modalData[id] = data;
  } else {
    modalData[id] = { ...modalData[id], ...data };
  }
};

export const deleteModalData = (id: string | number) => {
  delete modalData[id];
};
