import { Radio as BaseRadio, RadioProps } from 'antd';
import styled from 'styled-components';

export type { RadioProps };

export const Radio: typeof BaseRadio = styled(BaseRadio)`
  margin-right: 0;

  .ant-radio-inner {
    background: var(--spectrum-box-bg);
    border-color: var(--spectrum-box-border-color);

    &::after {
      background: var(--spectrum-primary-color);
    }
  }

  .ant-radio:hover,
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: var(--spectrum-primary-color);
    }
  }

  .ant-radio-checked::after {
    border-color: var(--spectrum-primary-color);
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px var(--spectrum-connect-wallet-btn-bg);
  }
`;
