import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

export interface FadeInProps {
  duration?: number;
  delay?: number;
}

export const FadeIn: FC<PropsWithChildren<FadeInProps>> = ({
  duration = 300,
  delay = 50,
  children,
}) => {
  const [visible, setVisible] = useState<boolean>(!delay);

  useEffect(() => {
    if (delay) {
      setTimeout(() => setVisible(true), delay);
    }
  }, []);

  return (
    <div
      style={{
        opacity: visible ? 1 : 0,
        transition: `opacity ${duration}ms ${delay ? `${delay}ms` : ''}`,
      }}
    >
      {children}
    </div>
  );
};
