import './Typography.less';

import { Typography as BaseTypography } from 'antd';
import { TextProps } from 'antd/lib/typography/Text';
import cn from 'classnames';
import React, { FC } from 'react';

type Align = 'left' | 'right' | 'center';

interface Extensions {
  Body: FC<
    {
      align?: Align;
      size?: BodyFontSize;
      secondary?: boolean;
      tooltip?: boolean;
      hint?: boolean;
    } & TextProps
  >;
}

export type BodyFontSize =
  | 'large'
  | 'base'
  | 'small'
  | 'extra-small'
  | 'footnote';

const Typography: typeof BaseTypography & Extensions = BaseTypography as any;
// eslint-disable-next-line react/display-name
Typography.Body = ({
  children,
  align,
  size,
  secondary,
  tooltip,
  className,
  hint,
  ...other
}: TextProps & {
  align?: Align;
  size?: BodyFontSize;
  secondary?: boolean;
  tooltip?: boolean;
  hint?: boolean;
}) => (
  <Typography.Text
    className={cn(
      `ant-typography-align--${align || 'initial'}`,
      {
        'ant-typography--small': size === 'small',
        'ant-typography--large': size === 'large',
        'ant-typography--extra-small': size === 'extra-small',
        'ant-typography--footnote': size === 'footnote',
        'ant-typography--secondary': secondary,
        'ant-typography--tooltip': tooltip,
        'ant-typography--hint': hint,
      },
      className,
    )}
    {...other}
  >
    {children}
  </Typography.Text>
);

export { Typography };
export type { TextProps as BodyProps };
