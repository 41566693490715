import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { LoadingOutlined } from '../../Icon/Icon';
import { Typography } from '../../Typography/Typography';
import { DataStateView } from '../DataStateView/DataStateView';

export interface LoadingDataStateProps {
  readonly height: number;
  readonly children?: ReactNode | ReactNode[];
  readonly transparent?: boolean;
}

const LoadingIcon = styled(LoadingOutlined)`
  font-size: 40px;
  color: var(--spectrum-table-view-icon);
`;

export const LoadingDataState: FC<LoadingDataStateProps> = ({
  height,
  children,
  transparent,
}) => (
  <DataStateView
    height={height}
    icon={<LoadingIcon />}
    transparent={transparent}
  >
    <Typography.Body align="center">{children}</Typography.Body>
  </DataStateView>
);
