import React, { FC, ReactNode } from 'react';

import { Typography } from '../../Typography/Typography';
import { DataStateView } from '../DataStateView/DataStateView';
import EmptyIcon from './empty.svg';

export interface EmptyDataStateProps {
  readonly height: number;
  readonly children?: ReactNode | ReactNode[];
}

export const EmptyDataState: FC<EmptyDataStateProps> = ({
  height,
  children,
}) => (
  <DataStateView height={height} icon={<EmptyIcon />} iconGutter={2}>
    <Typography.Body align="center">{children}</Typography.Body>
  </DataStateView>
);
