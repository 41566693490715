import React, { createContext, FC, ReactNode, useState } from 'react';

interface ModalContextType {
  readonly title: ReactNode;
  readonly setTitle: (title: ReactNode) => void;
  readonly id: number;
}

export const ModalContext = createContext<ModalContextType>({
  title: '',
  id: 0,
  setTitle: () => {},
});

export const ModalContextProvider: FC<{ children: any; id: number }> = ({
  children,
  id,
}) => {
  const [title, setTitle] = useState<ReactNode>('');
  return (
    <ModalContext.Provider value={{ title, setTitle, id }}>
      {children}
    </ModalContext.Provider>
  );
};
