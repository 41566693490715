import React, { CSSProperties, FC, ReactNode } from 'react';

import { Box } from '../../Box/Box';
import { Flex } from '../../Flex/Flex';

export interface DataStateViewProps {
  readonly className?: string;
  readonly height: CSSProperties['height'];
  readonly icon?: ReactNode | ReactNode[] | string;
  readonly iconGutter?: number;
  readonly children?: ReactNode | ReactNode[];
  readonly transparent?: boolean;
}

export const DataStateView: FC<DataStateViewProps> = ({
  className,
  height,
  icon,
  children,
  iconGutter,
  transparent,
}) => (
  <Box
    glass
    className={className}
    height={height}
    bordered={!transparent}
    transparent={transparent}
    borderRadius="l"
    secondary
  >
    <Flex stretch align="center" justify="center" col>
      {icon && (
        <Flex.Item marginBottom={children ? iconGutter || 4 : 0}>
          {icon}
        </Flex.Item>
      )}
      {children}
    </Flex>
  </Box>
);
