import './Box.less';

import cn from 'classnames';
import React, { CSSProperties, MouseEventHandler } from 'react';

import { getGutter, Gutter } from '../../utils';

interface BoxProps extends React.PropsWithChildren<unknown> {
  borderRadius?: 'none' | 's' | 'm' | 'l' | 'xl';
  secondary?: boolean;
  glass?: boolean;
  transparent?: boolean;
  inline?: boolean;
  className?: string;
  padding?: Gutter;
  maxHeight?: number;
  overflow?: boolean;
  bordered?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  onMouseEnter?: MouseEventHandler<HTMLElement> | undefined;
  onMouseLeave?: MouseEventHandler<HTMLElement> | undefined;
  width?: CSSProperties['width'];
  style?: CSSProperties;
  height?: string | number;
}

const Box = ({
  children,
  className,
  borderRadius,
  secondary,
  padding,
  transparent,
  inline,
  onClick,
  maxHeight,
  overflow,
  onMouseEnter,
  onMouseLeave,
  width,
  height,
  bordered,
  style,
  glass,
}: BoxProps): JSX.Element => {
  return (
    <div
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      className={cn(
        'spectrum-box',
        borderRadius && `spectrum-box--radius-${borderRadius}`,
        secondary && 'spectrum-box--secondary',
        (bordered === undefined || bordered) && 'spectrum-box--bordered',
        transparent && 'spectrum-box--transparent',
        glass && 'spectrum-box--glass',
        inline && 'spectrum-box--inline',
        onClick && 'spectrum-box--hoverable',
        className,
      )}
      style={{
        ...style,
        padding:
          padding != null
            ? getGutter(padding)
            : 'calc(var(--spectrum-base-gutter))',
        maxHeight: `${maxHeight}px`,
        overflow: overflow ? 'auto' : 'none',
        width: width,
        height: typeof height === 'number' ? `${height}px` : height,
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export { Box };
