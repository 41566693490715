import React, { FC, PropsWithChildren, useContext, useEffect } from 'react';

import { Typography } from '../../Typography/Typography';
import { ModalContext } from '../ModalContext/ModalContext';

export const ModalInnerTitle: FC = () => {
  const { title } = useContext(ModalContext);

  return <Typography.Title level={4}>{title}</Typography.Title>;
};

export const ModalTitle: FC<PropsWithChildren<any>> = ({ children }) => {
  const { setTitle } = useContext(ModalContext);

  useEffect(() => {
    setTitle(children);
  }, [children, setTitle]);

  return <></>;
};
