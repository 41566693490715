import './Tabs.less';

import { Tabs as AndtTabs, TabsProps } from 'antd';
import cn from 'classnames';
import React, { FC } from 'react';

export const Tabs: FC<
  TabsProps & { fullWidth?: boolean; centered?: boolean; glass?: boolean }
> & {
  TabPane: typeof AndtTabs.TabPane;
} = ({ className, fullWidth, centered, glass, ...rest }) => (
  <AndtTabs
    {...rest}
    className={cn(className, {
      'ant-tabs_full-width': fullWidth,
      'ant-tabs_text-center': centered,
      'ant-tabs_glass': glass,
    })}
  />
);
Tabs.TabPane = AndtTabs.TabPane;
