import './Alert.less';

import { Alert as AntdAlert, AlertProps as AntdAlertProps } from 'antd';
import cn from 'classnames';
import React from 'react';

export interface AlertProps extends AntdAlertProps {
  noBorder?: boolean;
  borderRadius?: 'xs' | 's' | 'm' | 'l' | 'xl';
}

export const Alert: React.FC<AlertProps> = ({
  className,
  noBorder,
  borderRadius,
  ...rest
}) => {
  return (
    <AntdAlert
      {...rest}
      className={cn(
        className,
        noBorder ? 'alert-not-border' : '',
        borderRadius ? `alert-border-radius-${borderRadius}` : '',
      )}
    />
  );
};
