import React, {
  CSSProperties,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react';
import styled from 'styled-components';

import { normalizeMeasure } from '../../../utils/normalizeMeasure';
import { ModalContext } from '../ModalContext/ModalContext';

export interface ModalContentProps {
  readonly width?: CSSProperties['width'];
  readonly maxWidth?: CSSProperties['maxWidth'];
  readonly className?: string;
  readonly style?: CSSProperties;
}

const _ModalContent: FC<PropsWithChildren<ModalContentProps>> = ({
  width,
  maxWidth,
  children,
  className,
  style,
}) => {
  const { id } = useContext(ModalContext);

  useEffect(() => {
    document.body.style.setProperty(
      `--spectrum-modal-${id}-width`,
      width ? normalizeMeasure(width) : null,
    );
    document.body.style.setProperty(
      `--spectrum-modal-${id}-max-width`,
      maxWidth ? normalizeMeasure(maxWidth) : null,
    );

    return () => {
      document.body.style.removeProperty(`--spectrum-modal-${id}-width`);
      document.body.style.removeProperty(`--spectrum-modal-${id}-max-width`);
    };
  }, [width, maxWidth]);

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export const ModalContent = styled(_ModalContent)`
  padding: 0 var(--spectrum-modal-padding) var(--spectrum-modal-padding);
  width: 100%;
`;
