import './Tooltip.less';

import { Tooltip as BaseTooltip, TooltipProps as BaseTooltipProps } from 'antd';
import React from 'react';
import { FC } from 'react';

//@ts-ignore
export type TooltipProps = {
  width?: number | string;
  maxWidth?: number | string;
} & BaseTooltipProps;

export const Tooltip: FC<TooltipProps> = ({
  width,
  maxWidth,
  title,
  ...rest
}) => {
  return (
    <BaseTooltip
      {...rest}
      title={<div style={{ width, maxWidth }}>{title}</div>}
    />
  );
};
