import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { SearchOutlined } from '../../Icon/Icon';
import { Typography } from '../../Typography/Typography';
import { DataStateView } from '../DataStateView/DataStateView';

export interface SearchDataStateProps {
  readonly height: number;
  readonly children?: ReactNode | ReactNode[];
}

const SearchIcon = styled(SearchOutlined)`
  font-size: 40px;
  color: var(--spectrum-table-view-icon);
`;

export const SearchDataState: FC<SearchDataStateProps> = ({
  height,
  children,
}) => (
  <DataStateView height={height} icon={<SearchIcon />}>
    <Typography.Body align="center">{children}</Typography.Body>
  </DataStateView>
);
