import { useState } from 'react';

type SetSearch = (term: string) => void;

type SearchByTerm<T> = (items: T[] | undefined) => T[];

export const searchByTermAndKeys = <T>(
  items: T[] = [],
  term: string | undefined = undefined,
  keys: (keyof T)[] = [],
): T[] => {
  const normalizedTerm = term?.toLowerCase();

  if (!normalizedTerm) {
    return items;
  }

  return items.filter((i) =>
    keys.some((k) => {
      const iKeyValue = i[k];

      if (typeof iKeyValue !== 'string') {
        return false;
      }

      return iKeyValue.toLowerCase().includes(normalizedTerm);
    }),
  );
};

export const searchByTermAndCallback = <T>(
  items: T[] = [],
  term: string | undefined = undefined,
  callback: (item: T, term?: string) => boolean,
): T[] => {
  const normalizedTerm = term?.toLowerCase();

  if (!normalizedTerm) {
    return items;
  }

  return items.filter((i) => callback(i, term));
};

export const useSearch = <T>(
  param: (keyof T)[] | ((item: T, term?: string) => boolean),
): [SearchByTerm<T>, SetSearch, string] => {
  const [term, setTerm] = useState<string>('');

  const setSearch = (term: string | undefined) =>
    setTerm(term?.toLowerCase() || '');

  const _searchByTerm = (items: T[] | undefined) =>
    param instanceof Array
      ? searchByTermAndKeys(items, term, param)
      : searchByTermAndCallback(items, term, param);

  return [_searchByTerm, setSearch, term];
};
