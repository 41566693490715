import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Button, ButtonProps } from '../Button/Button';

export interface ConnectWalletButtonProps extends ButtonProps {
  readonly isWalletConnected?: boolean;
  readonly caption?: ReactNode | ReactNode[] | string;
}

const _ConnectWalletButton: FC<ConnectWalletButtonProps> = ({
  isWalletConnected,
  children,
  caption,
  ...rest
}) => (
  <>{isWalletConnected ? children : <Button {...rest}>{caption}</Button>}</>
);

export const ConnectWalletButton = styled(_ConnectWalletButton)`
  border-color: transparent;
  background: var(--spectrum-connect-wallet-btn-bg) !important;
  backdrop-filter: var(--spectrum-box-bg-filter);
  color: var(--spectrum-connect-wallet-btn-color);

  &:hover {
    border-color: var(--spectrum-connect-wallet-btn-border);
  }
`;
