import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Observable } from 'rxjs';

import { ModalRef } from '../Modal';
import { setModalData } from '../modalData';
import { Error } from './Error';
import { Progress } from './Progress';
import { Success } from './Success';

export interface RequestProps {
  readonly actionContent: (
    next: (request: Promise<any> | Observable<any>) => void,
  ) => ReactNode | ReactNode[] | string;
  readonly progressContent: ReactNode | ReactNode[] | string;
  readonly errorContent:
    | ReactNode
    | ReactNode[]
    | string
    | ((result: any) => ReactNode | ReactNode[] | string);
  readonly successContent:
    | ReactNode
    | ReactNode[]
    | string
    | ((result: any) => ReactNode | ReactNode[] | string);
  readonly success?: (result: any) => ReactNode | ReactNode[] | string;
  readonly onCancel?: () => void;
}

export enum RequestState {
  ACTION,
  PROGRESS,
  ERROR,
  SUCCESS,
}

export const Request: FC<RequestProps & ModalRef & { id: string }> = ({
  progressContent,
  errorContent,
  successContent,
  success,
  actionContent,
  close,
  onCancel,
  id,
}) => {
  const CustomSuccess = success;
  const [requestData, setRequestData] = useState<{
    state: RequestState;
    result: any;
  }>({
    state: RequestState.ACTION,
    result: null,
  });

  const handleRequest = (request: Promise<any> | Observable<any>) => {
    setRequestData({ state: RequestState.PROGRESS, result: null });
    (request instanceof Observable ? request.toPromise() : request)
      .then((result) => {
        setRequestData({ result, state: RequestState.SUCCESS });
      })
      .catch((error) =>
        setRequestData({ result: error, state: RequestState.ERROR }),
      );
  };

  const handleClose = () => {
    close(requestData.state);
    if (requestData.state === RequestState.ACTION && onCancel) {
      onCancel();
    }
  };

  useEffect(() => {
    setModalData(id, { state: requestData.state });
  }, [requestData.state]);

  return (
    <>
      {requestData.state === RequestState.ACTION &&
        actionContent(handleRequest)}
      {requestData.state === RequestState.PROGRESS && (
        <Progress content={progressContent} />
      )}
      {requestData.state === RequestState.ERROR && (
        <Error result={requestData.result} content={errorContent} />
      )}
      {requestData.state === RequestState.SUCCESS &&
        (CustomSuccess ? (
          CustomSuccess(requestData.result)
        ) : (
          <Success result={requestData.result} content={successContent} />
        ))}
    </>
  );
};
